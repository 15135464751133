<template>
  <div class="text-center">
    <div class="text-h2 font-weight-bold text-uppercase">{{ $t('wot.notFoundTitle') }}</div>
    <v-icon class="mt-3 warning--text" size="80">{{ icons.mdiAlert }}</v-icon>
    <p class="mt-3 text-body-1">{{ $t('wot.notFound') }}</p>
    <v-btn color="primary" :to="{ name: 'home' }">{{ $t('wot.backToHome') }}</v-btn>
  </div>
</template>

<script>
import { mdiAlert } from '@mdi/js';

export default {
  data: () => ({
    icons: { mdiAlert },
  }),
};
</script>
